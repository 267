import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import ThemeSwitch from './ThemeSwitch'
import Memoji from '../images/memoji.png'

const Header = () => {

  return (
    <header>
      <AnchorLink to='/' aria-label='top'>
        <div className='avatar'>
          <img src={Memoji} alt='Chad Bergman' />
        </div>
      </AnchorLink>
      <ThemeSwitch />
    </header>
  )
}

export default Header