import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { DarkModeSwitch } from 'react-toggle-dark-mode'

const ThemeSwitch = () => {

  return (
    <div className='theme-toggle'>
      <ThemeToggler>
        {({ theme, toggleTheme }) => (
        <label htmlFor='toggle' aria-label='Change color theme'>
          <input
            type='checkbox'
            id='toggle'
            onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
            checked={theme === 'dark'}
          />{' '}
          <div className="icon-switch">
            <DarkModeSwitch theme={theme}
              checked={theme === 'dark'}
              onChange={e => console.log(e)}
              sunColor=''
              moonColor='var(--color-text-heading)'
            />
          </div>
        </label>
        )}    
      </ThemeToggler>
    </div>
  );
};

export default ThemeSwitch